<template>
  <div class="days d-inline-flex rounded-8 mx-auto">
    <router-link
      class="btn px-16"
      replace
      v-for="day of dayOptions"
      :class="{ active: day.value === activeDay }"
      :key="day.value"
      :to="{ query: { ...$route.query, day: day.value } }"
    >
      {{ day.label }}
    </router-link>
  </div>
</template>

<script>
import dayjs from '../../bootstrap/dayjs';
import Schedule from '../../store/models/Schedule';

export default {
  computed: {
    dayOptions() {
      return Schedule.dayOptions;
    },

    activeDay() {
      const activeDay = Number(this.$route.query.day);
      const currentDay = dayjs().weekday();

      return Number.isInteger(activeDay) ? activeDay : currentDay;
    },
  },
};
</script>

<style lang="scss" scoped>
.days {
  background: $gray-200;
  padding: rem(4) 0 rem(4) rem(4);
  overflow-y: auto;

  .btn {
    margin-right: rem(4);

    &:hover,
    &:focus {
      background: $white;
    }

    &.active {
      background: $primary;
      color: $white;
    }
  }
}
</style>
