<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="d-flex justify-content-center mb-32">
      <DayButtons />
    </div>

    <template v-if="activeSchedules.length">
      <ScheduleItem
        class="mt-16"
        v-for="schedule of activeSchedules"
        :key="schedule.id"
        :schedule="schedule"
      />
    </template>
    <p class="text-muted text-center" v-else>Tidak ada jadwal</p>
  </main>
</template>

<script>
import filter from 'lodash/filter';
import dayjs from '../../../bootstrap/dayjs';

import DayButtons from '../../../components/schedule/DayButtons.vue';
import ScheduleItem from '../../../components/teacher/schedule/ScheduleItem.vue';

export default {
  components: { DayButtons, ScheduleItem },

  data() {
    return { activeDay: dayjs().weekday() };
  },

  computed: {
    params() {
      return { sort: { day: 'asc', time_start: 'asc' } };
    },

    activeSchedules() {
      const schedules = this.$store.getters['schedules/get'](this.params);
      const activeDay = Number(this.$route.query.day);
      const currentDay = dayjs().weekday();

      return filter(schedules, [
        'day',
        Number.isInteger(activeDay) ? activeDay : currentDay,
      ]);
    },
  },

  metaInfo: { title: 'Jadwal' },

  beforeMount() {
    this.$store.dispatch('schedules/maybeFetch', this.params);
  },
};
</script>
